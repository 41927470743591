<template>
  <div class="main">
  <el-container>
    <el-aside width="300px">
      <el-row>
        <el-col :span="8" :offset="1">
        <el-button type="text" @click="openSearch">{{$t('circularFence.query')}} </el-button>
        </el-col>
        <el-col :span="8" :offset="6">
        <el-button type="text" @click="addFence">{{$t('circularFence.addFence')}}</el-button>
        </el-col>
      </el-row>
      <el-table :data="circularFenceList" :border="true" stripe>
        <el-table-column prop="fenceId" label="Fence Id" v-if="false"></el-table-column>
        <el-table-column prop="fenceName" :label="$t('circularFence.fenceName')" width="100px"></el-table-column>
        <el-table-column prop="radius" :label="$t('circularFence.radius')" width="60px"></el-table-column>
        <el-table-column prop="inOut" :label="$t('circularFence.inOut')" :formatter="inOutFormatter" width="60px"></el-table-column>
        <el-table-column :label="$t('common.operation')" width="78px">
        <template slot-scope="scope">
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            circle
            @click="deleteFence(scope.row.fenceId)"
          ></el-button>
        </template>
        </el-table-column>
      </el-table>
    </el-aside>
    <div v-if="searchDialogVisible" class="searchClass">
      <el-card>
        <el-form :model="locatePoint"
                 ref="locatePointRef"
                 label-width="90px">
          <el-form-item :label="$t('circularFence.latitude')"  >
            <el-input type="number" v-model="locatePoint.lat"></el-input>
          </el-form-item>
          <el-form-item :label="$t('circularFence.longitude')" >
            <el-input type="number" v-model="locatePoint.lng" ></el-input>
          </el-form-item>
        </el-form>
        <el-button type="warning" @click="searchDialogVisible = false" >{{$t('common.close')}}</el-button>
        <el-button type="primary" @click="locate" >{{$t('common.search')}}</el-button>
      </el-card>
    </div>
    <el-main>
      <div class="circularFence">
        <GmapMap
          ref="mapRef"
          map-type-id="terrain"
          v-if="currentMap === 'google'"
          :center="{lat:25.037798, lng:121.565170}"
          :zoom="13"
          style="width: 100%; height: 100%"
          :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUI: false
            }"></GmapMap>
        <baidu-map v-if="currentMap === 'baidu'" class="baidu-map" :center="center" :zoom="13" @ready="handleBaidu"
                   :scroll-wheel-zoom='true'>
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"/>
          <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"/>
        </baidu-map>
      </div>
    </el-main>
  </el-container>
    <el-dialog :title="$t('circularFence.addFenceTitle')" :visible.sync="addDialogVisible" width="20%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addForm"
        ref="addFormRef"
        :rules="addFormRules"
        label-width="90px"
      >
        <el-form-item :label="$t('circularFence.fenceName')" prop="fenceName" >
          <el-input v-model="addForm.fenceName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('circularFence.radius')" prop="radius">
          <el-input v-model="addForm.radius" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('circularFence.inOut')" prop="inOut">
          <el-select v-model="addForm.inOut"
                     size="small" >
            <el-option value="2" label="For Out"></el-option>
            <el-option value="1" label="For In"></el-option>
            <el-option value="3" label="For In/Out"></el-option>
          </el-select>
        </el-form-item>
        <span  class="dialog-footer myButton">
        <el-button @click="addDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="addCircularFence" >{{$t('common.confirm')}}</el-button>
      </span>
      </el-form>
    </el-dialog >
  </div>
</template>

<script>
import { getGpsDeviceByImei } from '@/api/gpsDevice'
import { checkLogin } from '@/api/globalProcessErr'
import { listCircularFence, deleteCircularFence, insertCircularFence } from '@/api/circularFence'

import { initMap, addCircle, cleanAllCircle, makeMarker, batchMakeCircleMarker, cleanAllCircleMark, moveToCenter } from '@/api/map'
import { deviceStatusFormat, getObjString } from '@/api/util'

export default {
  name: 'circularFence',
  data () {
    return {
      deviceImei: '',
      center: {
        lat: 22.5711440000,
        lng: 113.8687300000
      },
      circularFenceList: [],
      clickedPoint: {
        lat: undefined,
        lng: undefined
      },
      device: undefined,
      mapInstance: undefined,
      devicePath: [],
      prePath: undefined,
      currPath: undefined,
      deviceMark: {
        deviceImei: '',
        marker: undefined,
        infoWindow: undefined
      },
      addDialogVisible: false,
      addForm: {
        fenceName: '',
        radius: undefined,
        inOut: undefined
      },
      circleList: [],
      locatePoint: {
        lat: undefined,
        lng: undefined
      },
      searchDialogVisible: false,
      currentMap: 'baidu'
    }
  },
  computed: {
    addFormRules () {
      return {
        fenceName: [
          { required: true, message: this.$t('circularFence.rules.fenceName.required'), trigger: 'blur' }
        ],
        radius: [
          { required: true, message: this.$t('circularFence.rules.radius.required'), trigger: 'blur' }
        ],
        inOut: [
          { required: true, message: this.$t('circularFence.rules.inOut.required'), trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.deviceImei = window.sessionStorage.getItem('circularFenceDeviceImei') || ''
    this.checkLang()
    // this.getGpsDevice()
    // this.getCircularFenceList()
  },
  mounted () {
    const _this = this
    setTimeout(function () {
      _this.initMapPage()
    }, 500)
    setTimeout(function () {
      _this.drawFence()
    }, 800)
  },
  methods: {
    checkLang () {
      if (this.$i18n.locale === 'zh-CN') {
        this.currentMap = 'baidu'
      } else {
        this.currentMap = 'google'
      }
    },
    handleBaidu ({ BMap, map }) {
      this.mapInstance = {
        mapType: 'baidu',
        map: map,
        bMap: BMap,
        // map: window.$BAIDU$._instances.TANGRAM__1,
        // bMap: window.BMap,
        markList: [],
        currentOpenInfoWindow: undefined,
        contentFunc: this.makeContent,
        currentPoly: undefined,
        polyList: [],
        haveBtn: false,
        currentPoint: undefined,
        circleList: [],
        circleMarkList: [],
        clickFunc: this.clickFunc
      }
    },
    initMapPage () {
      if (this.currentMap === 'google') {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'google',
            map: this.$refs.mapRef.$mapObject,
            mapRef: this.$refs.mapRef,
            googleMaps: window.google.maps,
            markList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.makeContent,
            currentPoly: undefined,
            polyList: [],
            haveBtn: false,
            currentPoint: undefined,
            circleList: [],
            circleMarkList: [],
            clickFunc: this.clickFunc
          }
        }
      } else {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'baidu',
            // map: map,
            // bMap: BMap,
            map: window.$BAIDU$._instances.TANGRAM__1,
            bMap: window.BMap,
            markList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.makeContent,
            currentPoly: undefined,
            polyList: [],
            haveBtn: false,
            currentPoint: undefined,
            circleList: [],
            circleMarkList: [],
            clickFunc: this.clickFunc
          }
        }
      }
      initMap(this.mapInstance, this.center, 13)
    },
    makeContent (data, haveBtn) {
      const self = this
      // const motionStatus = self.motionStatusFormatter(data)
      return function () {
        let item = 'engine'
        let content = '<style>'
        content = content + '.deviceInfo{ padding: 0 0 0 0; margin: 0 0 0 0; .columnName{ width: 25%; } .columnValue{ width: 25%; }}'
        content = content + '</style>'
        content = content + '<div class="deviceInfo"><table frame="void">'
        content = content + '<tr align="left">'
        content = content + '<td class="columnName"><b> ' + self.$t('device.deviceImei') + '</b> : </td>'
        content = content + '<td class="columnValue">' + data.deviceImei + '</td>'
        content = content + '<td class="columnName"><b> ' + self.$t('device.deviceName') + '</b> : </td>'
        content = content + '<td class="columnValue">' + data.deviceName + '</td>'
        content = content + '</tr>'
        content = content + '<tr align="left">'
        content = content + '<td class="columnName"><b> ' + self.$t('device.lastLatitude') + '</b> : </td>'
        content = content + '<td class="columnValue">' + getObjString(data.lastLatitude) + '</td>'
        content = content + '<td class="columnName"><b> ' + self.$t('device.lastLongitude') + '</b> ：</td>'
        content = content + '<td class="columnValue">' + getObjString(data.lastLongitude) + '</td>'
        content = content + '</tr>'
        content = content + '</tr>'
        content = content + '<tr align="left">'
        content = content + '<td class="columnName"><b> ' + self.$t('device.accStatus') + '</b> : </td>'
        content = content + '<td class="columnValue">' + deviceStatusFormat(data, item) + '</td>'
        content = content + '<td class="columnName" ><b> ' + self.$t('device.lastLocationTime') + '</b> ：</td>'
        content = content + '<td class="columnValue" style="width: 160px;">' + getObjString(data.lastLocationTime) + '</td>'
        content = content + '</tr>'
        content = content + '<tr align="left">'
        content = content + '<td class="columnName"><b> ' + self.$t('device.lastSpeed') + '</b> : </td>'
        content = content + '<td class="columnValue">' + getObjString(data.lastSpeed) + '</td>'
        content = content + '<td class="columnName" ><b> ' + self.$t('device.heading') + '</b> ：</td>'
        content = content + '<td class="columnValue" >' + getObjString(data.heading) + '</td>'
        content = content + '</tr>'

        if (haveBtn) {
          content = content + '<tr><td> <button onclick="realTimeTrack(\'' + data.deviceImei + '\')" style="width: 150px;" type="button"> Real time tracking </button> </td>'
          content = content + '<td> <button onclick="historicalTrack(\'' + data.deviceImei + '\')" style="width: 150px; type="button"> Historical track </button> </td>'
          content = content + '<td> <button onclick="sendCommand(\'' + data.deviceImei + '\')" style="width: 150px; type="button"> Send Command </button></td>'
          content = content + '<td> <button onclick="getDeviceInfo(\'' + data.deviceImei + '\')" style="width: 150px; type="button"> Device Infomation</button> </td></tr>'
        }

        content += '</table></div>'

        return content
      }
    },
    motionStatusFormatter (row, column) {
      if (row.lastMotionStatus === 1) {
        return 'motion'
      } else if (row.lastMotionStatus === 0) {
        return 'static'
      } else {
        return ''
      }
    },
    openSearch () {
      this.searchDialogVisible = !this.searchDialogVisible
    },
    addFence () {
      this.addDialogVisible = true
    },
    addCircularFence () {
      const _this = this
      if (this.clickedPoint.lat === undefined) {
        return this.$message.error(_this.$t('circularFence.rules.selectPoint'))
      }
      const req = {
        deviceImei: this.deviceImei,
        fenceName: this.addForm.fenceName,
        radius: this.addForm.radius,
        latitude: this.clickedPoint.lat,
        longitude: this.clickedPoint.lng,
        inOut: this.addForm.inOut
      }
      insertCircularFence(req).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.$message.success(_this.$t('common.createSuccess'))
        _this.addDialogVisible = false
        _this.getCircularFenceList()
        setTimeout(function () {
          _this.drawFence()
        }, 300)
      }).catch(err => {
        return _this.$message.error(err.message)
      })
    },
    inOutFormatter (row, column) {
      if (row.inOut === 1) {
        return 'For In'
      } else if (row.inOut === 2) {
        return 'For Out'
      } else {
        return 'For In/Out'
      }
    },
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    deleteFence (fenceId) {
      const req = {
        fenceId: fenceId
      }
      const self = this
      deleteCircularFence(req).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        self.$message.success(self.$t('common.updateSuccess'))
        self.getCircularFenceList()
        setTimeout(function () {
          self.drawFence()
        }, 300)
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    drawFence () {
      cleanAllCircle(this.mapInstance)
      cleanAllCircleMark(this.mapInstance)
      for (let i = 0; i < this.circularFenceList.length; i++) {
        const point = {
          lat: this.circularFenceList[i].latitude,
          lng: this.circularFenceList[i].longitude
        }
        addCircle(this.mapInstance, point, this.circularFenceList[i].radius)
      }
      batchMakeCircleMarker(this.mapInstance, this.circularFenceList)
      makeMarker(this.mapInstance, this.device)
    },
    getCircularFenceList () {
      const req = {
        deviceImei: this.deviceImei
      }
      const self = this
      listCircularFence(req).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.circularFenceList = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    getGpsDevice () {
      getGpsDeviceByImei(this.deviceImei).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.device = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    locate () {
      const self = this
      if (this.locatePoint.lat === undefined || this.locatePoint.lng === undefined) {
        return this.$message.error(self.$t('circularFence.rules.latLng'))
      }
      const center = {
        lat: Number(this.locatePoint.lat),
        lng: Number(this.locatePoint.lng)
      }
      if (center.lat > 90 || center.lat < -90) {
        return this.$message.warning(self.$t('circularFence.rules.lat'))
      }
      if (center.lng > 180 || center.lng < -180) {
        return this.$message.warning(self.$t('circularFence.rules.lng'))
      }
      moveToCenter(self.mapInstance, center)
    },
    clickFunc (e) {
      if (e !== undefined) {
        this.clickedPoint.lat = e.lat
        this.clickedPoint.lng = e.lng
      }
    }
  }
}
</script>

<style scoped>

.el-aside {
  height: 100% !important;
  position: relative;
}

.el-main {
  height: 99% !important;
  width: 100%;
  margin: 0 0 0 0;
  position: relative;
}

.baidu-map {
  width: 100% !important;
  height: 900px !important;
  margin: 0 0 0 0;
  position: relative;
}

.GmapMap {
  width: 100% !important;
  height: 900px !important;
  margin: 0 0 0 0;
  /*position: relative;*/
}

.circularFence {
  width: 100%;
  height: 900px !important;
  /*float: left;*/
  margin: 0 0 0 0;
  left: 300px;
}

.searchClass {
  position: absolute;
  width: 230px;
  margin: 0 auto;
  left: 20%;
  z-index: 10;
  bottom: 30px;
}

/*.searchClass {
  position: absolute;
  width: 230px;
  margin: 0 auto;
  left: 35%;
  z-index: 10;
  bottom: 30px;
}*/

</style>
