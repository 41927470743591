import request from '@/api/request'

export function listCircularFence (data) {
  const req = {
    deviceImei: data.deviceImei
  }
  return request({
    url: 'app/circularFence/list',
    method: 'post',
    data: req
  })
}

export function insertCircularFence (data) {
  const req = {
    deviceImei: data.deviceImei,
    fenceName: data.fenceName,
    latitude: data.latitude,
    longitude: data.longitude,
    radius: data.radius,
    inOut: data.inOut
  }
  return request({
    url: 'circularFence/insertCircularFence',
    method: 'post',
    data: req
  })
}

export function deleteCircularFence (data) {
  const req = {
    fenceId: data.fenceId
  }
  return request({
    url: 'circularFence/deleteCircularFence',
    method: 'post',
    data: req
  })
}
